<template>
  <div class="relative z-10 overflow-y-scroll scrollbar-hide text-center h-full mx-6">
    <div class="mt-16">
      <h5 class="text-pink-cs text-base">موقعیت جغرافیایی</h5>
    </div>
    <div class="mt-8">
      <div class="my-location-city" @click="getNowLocation">
        <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 16.5 16.5">
          <path id="Icon_material-my-location" data-name="Icon material-my-location" d="M9.75,6.75a3,3,0,1,0,3,3A3,3,0,0,0,9.75,6.75ZM16.455,9A6.746,6.746,0,0,0,10.5,3.045V1.5H9V3.045A6.746,6.746,0,0,0,3.045,9H1.5v1.5H3.045A6.746,6.746,0,0,0,9,16.455V18h1.5V16.455A6.746,6.746,0,0,0,16.455,10.5H18V9Zm-6.7,6A5.25,5.25,0,1,1,15,9.75,5.246,5.246,0,0,1,9.75,15Z" transform="translate(-1.5 -1.5)" fill="#fff"/>
        </svg>
        موقعیت فعلی من
      </div>

    </div>
    <div class="mt-12">
      <p class="text-white">شهر یا محله مورد نظرتو وارد کن</p>
      <div class="search__wrapper" >
        <span class="svg-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="19.686" height="19.729" viewBox="0 0 19.686 19.729">
            <path id="Union_1" data-name="Union 1" d="M14.549,14.582l4.284,4.294ZM0,8.417a8.4,8.4,0,1,1,8.4,8.417A8.407,8.407,0,0,1,0,8.417Z" transform="translate(0.5 0.5)" fill="none" stroke="#ff7400" stroke-width="1"/>
          </svg>
        </span>
        <input type="submit" @click="handleCityFilter" :value="searchText" class="text-right text-gray-500 opacity-50 cursor-pointer">
      </div>

    </div>
    <img :src="image" class="rounded-2xl mt-8" ref="image" alt="">
    <button class="btn-custom w-full mt-8" @click="handleSubmit">
      ثبت و ادامه
      <span class="svg-btn-icon2 bg-orange transform rotate-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>
        </span>
    </button>

  </div>

</template>

<script>
import mapAxios from "@/plugins/mapAxios";
export default {
  name: "AddEstateStep4",
  data(){
    return{
      searchText : 'جستجو',
      image : null
    }
  },
  methods:{
    handleSubmit(){
      let address = this.$store.state.editEstate.address
      if (address !== ''){
        this.$router.push({name : 'EditEstateStep5'})
      }else {
        this.$toast.error('لطفا آدرس را وارد کنید')
      }

    },
    handleCityFilter(){
      this.$router.push({name : 'EditEstateSelectCity'})
    },
    getNowLocation() {
      navigator.geolocation.getCurrentPosition(this.success, this.error)
    },
    async success(pos) {
      var crd = pos.coords;
      const data = {
        lat : crd.latitude,
        lon : crd.longitude
      }
      await this.$store.commit('map/SET_SELECT_LOCATION', data)
      await this.$router.push({name : 'EditEstateMap'})
    },
    error() {
      this.$toast.error('دسترسی لوکشین را فعال کنید')
    },
  },
  mounted() {
    let crd = this.$store.state.editEstate.geoposition;
    console.log(crd)
    if (crd.lat !== null && crd.lon !== null ){
      mapAxios.get('/fast-reverse',{
        params:{
          lat : crd.latitude,
          lon : crd.longitude
        }
      }).then(({data})=>{
        this.searchText = data.address_compact
        mapAxios.get('/static',{
          params:{
            width : 370,
            height : 250,
            zoom_level : 14,
            markers : `color:blue|label:آدرس انتخاب شده|${crd.longitude},${crd.latitude}`
          },
          responseType: 'arraybuffer'
        }).then((res)=>{
          const base64 = btoa(
              new Uint8Array(res.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  '',
              ),
          );
          this.image = "data:;base64," + base64

        })
      })
    }
  }
}
</script>

<style scoped>

</style>